/* eslint-disable deprecation/deprecation */
import { Stack, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { URLKeys } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiButton, NoddiDialog, colors, useNoddiToast } from 'noddi-ui';
import { useState } from 'react';

import useAxiosPrivate from '../../../adapters/xhr/axiosPrivate';
import { ApiEndpointIdentifier } from '../../../constant/apiRoute';
import { getEndpointUrl } from '../../../helper';
import UnableToCompleteBlock, { UnableToCompleteItem } from './UnableToCompleteBlock';

interface SimplifiedSalesItem {
  id: number;
  name: string;
}

export function UnableToCompleteCarModal({
  unableToCompleteModal,
  setUnableToCompleteModal,
  routeId,
  routeItemId,
  bookingId,
  salesItems
}: {
  unableToCompleteModal: boolean;
  setUnableToCompleteModal: (unableToCompleteModal: boolean) => void;
  routeId: string;
  routeItemId: string;
  bookingId: number;
  salesItems: SimplifiedSalesItem[];
}) {
  const { noddiToast } = useNoddiToast();

  const mapSalesItemsToUnableToCompleteItems = (salesItems: SimplifiedSalesItem[]): UnableToCompleteItem[] => {
    return salesItems.map((item) => ({
      id: item.id,
      name: item.name,
      isSelected: true,
      unableToCompleteReasonId: null
    }));
  };
  const [salesItemsUnableToComplete, setSalesItemsUnableToComplete] = useState<UnableToCompleteItem[]>(
    mapSalesItemsToUnableToCompleteItems(salesItems)
  );
  const [commentInternal, setCommentInternal] = useState<string>('');
  const [isCommenInternalOpen, setIsCommentInternalOpen] = useState<boolean>(false);
  const [commentPublic, setCommentPublic] = useState<string>('');
  const axiosPrivate = useAxiosPrivate();
  const { mutateAsync: unableToCompleteCar, isPending: isUnableToCompleteCarLoading } = useMutation({
    mutationKey: [ApiEndpointIdentifier.UNABLE_TO_COMPELTE_CAR, routeItemId],
    mutationFn: async () => {
      const onlySelectedItems = salesItemsUnableToComplete.filter((item) => item.isSelected);
      return axiosPrivate
        .post(
          getEndpointUrl(ApiEndpointIdentifier.UNABLE_TO_COMPELTE_CAR, {
            routeItemId,
            routeId
          }),
          {
            booking_item_id: bookingId,
            comments_internal: commentInternal,
            comments_public: commentPublic,
            sales_items: onlySelectedItems.map((item) => ({
              sales_item_id: item.id,
              unable_to_complete_reason_id: item.unableToCompleteReasonId
            }))
          }
        )
        .then((res) => res.data);
    },
    onSuccess: async () => {
      invalidateQueryExactMatch({
        urlKey: URLKeys.getBookingItemsForRoute,
        input: { routeItemId: parseInt(routeItemId) }
      });
      noddiToast.success('Successfully set to unable to complete');
      setUnableToCompleteModal(false);
    }
  });

  const allSelectedItemsHasUnableToComplete = salesItemsUnableToComplete.every((item) =>
    item.isSelected ? Boolean(item.unableToCompleteReasonId) : true
  );

  const isAnySelected = salesItemsUnableToComplete.some((item) => item.isSelected);

  const disableConfirmButton = !commentPublic || !allSelectedItemsHasUnableToComplete || !isAnySelected;

  return (
    <NoddiDialog
      open={unableToCompleteModal}
      title='Unable to complete'
      onClose={() => {
        setUnableToCompleteModal(false);
      }}
    >
      <Stack>
        <>
          {salesItemsUnableToComplete.length > 0 ? (
            <>
              <Stack>
                <Typography color='text.secondary'>Select items unable to complete</Typography>
              </Stack>
              <Stack>
                {salesItemsUnableToComplete.map((item) => (
                  <UnableToCompleteBlock
                    key={item.id}
                    unableToCompleteItem={item}
                    setUnableToCompleteItems={setSalesItemsUnableToComplete}
                  />
                ))}
              </Stack>
              <Stack marginTop={2} direction='column' justifyContent='center' alignItems='center' gap={1}>
                <Typography color='text.secondary'>
                  Please explain to the customer why this booking was not possible to complete.
                </Typography>
                <TextField
                  fullWidth
                  rows={2}
                  multiline
                  value={commentPublic}
                  onChange={(e) => {
                    setCommentPublic(e.target.value);
                  }}
                  label='Comments to customer (Be Polite)'
                />
              </Stack>
              {commentPublic ? (
                <Stack mt={2}>
                  <Typography mb={1}>💬 Preview of message to customer</Typography>
                  <Typography
                    sx={{
                      backgroundColor: colors.primary.darkPurple30,
                      padding: 2,
                      borderRadius: 1
                    }}
                  >
                    Explanation from our technician is:
                    <br />
                    {commentPublic}
                  </Typography>
                </Stack>
              ) : null}

              <Stack mt={2} style={{ cursor: 'pointer' }} mb={1}>
                <div onClick={() => setIsCommentInternalOpen((prev) => !prev)}>
                  <Typography
                    style={{
                      textDecoration: 'underline',
                      color: colors.primary.orange
                    }}
                  >
                    Any more comments to the internal team?
                  </Typography>
                </div>
              </Stack>
              {isCommenInternalOpen ? (
                <TextField
                  fullWidth
                  rows={2}
                  multiline
                  value={commentInternal}
                  onChange={(e) => {
                    setCommentInternal(e.target.value);
                  }}
                  label='Comment (this not visible to the customer!)'
                />
              ) : null}
              <NoddiButton
                disabled={disableConfirmButton}
                className='mt-6 w-full'
                loading={isUnableToCompleteCarLoading}
                onClick={async () => {
                  await unableToCompleteCar();
                }}
              >
                Confirm
              </NoddiButton>
            </>
          ) : (
            <Typography color='error'>All sales items are already marked as unable to complete</Typography>
          )}
        </>
      </Stack>
    </NoddiDialog>
  );
}
