import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  EmailLogin,
  NoddiButton,
  NoddiFeedbackBox,
  NoddiLogo,
  SMSLogin,
  SectionSpacer,
  useGetScreenDimensions,
  useNavigateOnLoginSuccess
} from 'noddi-ui';
import { useState } from 'react';
import styled from 'styled-components';

import { tracking } from 'noddi-provider';
import { useLocation } from 'react-router-dom';
import routes from '../appRoutes';
import UserRegistrationForm from '../components/UserRegistrationForm';

const Container = styled.div`
  min-width: 330px;
  max-width: 550px;
  @media (width < 550px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 24px 24px 0px 24px;
`;

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');

  const { navigateOnLoginSuccess } = useNavigateOnLoginSuccess();
  const location = useLocation();

  const { screenSize } = useGetScreenDimensions();
  const [isSmsLogin, setIsSmsLogin] = useState(false); // if false, email login

  const containerHeight = screenSize.height * 0.75;

  function onLoginSuccess() {
    // get the redirect path from the query string
    const redirectPath = new URLSearchParams(location.search).get('redirect');

    if (redirectPath) {
      navigateOnLoginSuccess({ loginSuccessPath: redirectPath });
    } else {
      navigateOnLoginSuccess({ loginSuccessPath: routes.routes.getPath() });
    }
  }

  return (
    <Stack justifyContent='center' alignItems='center' minHeight={containerHeight} marginY={10}>
      <Container>
        <Card elevation={16}>
          <Header>
            <div style={{ margin: 'auto 0' }}>
              <Typography variant='h3'>Login</Typography>
            </div>
            <NoddiLogo />
          </Header>

          <CardContent style={{ paddingTop: '0px' }}>
            {isSmsLogin ? (
              <SMSLogin
                onLoginSuccess={onLoginSuccess}
                trackUserIdentity={(user) => {
                  tracking.trackUserLogin(user);
                }}
                userRegistrationForm={(phoneNumber, onUserRegistrationLoginSuccess) => (
                  <UserRegistrationForm
                    phoneNumber={phoneNumber}
                    onLoginSuccess={onUserRegistrationLoginSuccess}
                    trackUserIdentity={(user) => {
                      tracking.trackUserIdentity(user);
                    }}
                  />
                )}
              />
            ) : (
              <SectionSpacer>
                <div style={{ width: '100%' }}>
                  <EmailLogin
                    setErrorMessage={setErrorMessage}
                    onLoginSuccess={onLoginSuccess}
                    trackUserIdentity={(user) => {
                      tracking.trackUserLogin(user);
                    }}
                  />
                </div>
              </SectionSpacer>
            )}
            {errorMessage && <NoddiFeedbackBox variant='error' description={errorMessage} />}

            <NoddiButton endIcon='RightArrowAlt' variant='link' onClick={() => setIsSmsLogin(!isSmsLogin)}>
              Login with {isSmsLogin ? 'email' : ' SMS'}
            </NoddiButton>
          </CardContent>
        </Card>
      </Container>
    </Stack>
  );
};

export default Login;
