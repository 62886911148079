import { DialogActions, DialogContent, DialogProps, Stack } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { AxiosError } from 'axios';
import { NoddiButton } from '../Buttons';
import { ApiErrorMessage } from '../Errors';
import { NoddiDialog } from './NoddiDialog';

type NoddiAsyncError = AxiosError<{
  type: string;
  errors: { code: string; detail: string; attr: string }[];
}>;

type NoddiConfirmationDialogProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onConfirm: () => Promise<any>;
  onCancel?: () => void;
  open: boolean;
  onClose: () => void;
  description: ReactNode;
  confirmText: string;
  cancelText: string;
  isLoading?: boolean;
  error?: NoddiAsyncError | null;
  closeOnConfirm?: boolean;
  height?: string;
  disableConfirm?: boolean;
} & DialogProps;

const NoddiConfirmationDialog = ({
  onConfirm,
  onCancel,
  description,
  onClose,
  open,
  confirmText,
  cancelText,
  error,
  isLoading,
  children,
  closeOnConfirm = true,
  height,
  disableConfirm,
  ...rest
}: PropsWithChildren<NoddiConfirmationDialogProps>) => {
  return (
    <NoddiDialog open={open} onClose={onClose} {...rest}>
      <DialogContent sx={{ height: height ?? '100%' }}>
        {description}
        {children}
        {error && (
          <div className='my-3'>
            <ApiErrorMessage error={error} />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Stack direction='row' justifyContent='space-between' width='100%' marginX={5} gap={3}>
          <NoddiButton className='w-full' onClick={onCancel ?? onClose} variant='secondary' disabled={isLoading}>
            {cancelText}
          </NoddiButton>
          <NoddiButton
            className='w-full'
            onClick={async () => {
              await onConfirm();

              if (closeOnConfirm) {
                onClose();
              }
            }}
            disabled={isLoading || disableConfirm}
            loading={isLoading}
          >
            {confirmText}
          </NoddiButton>
        </Stack>
      </DialogActions>
    </NoddiDialog>
  );
};

export { NoddiConfirmationDialog };
