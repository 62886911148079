import HomeContentWrapper from '../../layouts/HomeContentWrapper';
import AllRegisteredWorPreferences from './AllRegisteredWorPreferences';

const WorkPreferences = () => {
  return (
    <HomeContentWrapper>
      <AllRegisteredWorPreferences />
    </HomeContentWrapper>
  );
};

export default WorkPreferences;
