import { URLKeys, noddiAsync } from 'noddi-async';
import { BookingItemOnRoute } from 'noddi-async/src/types';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { ApiErrorMessage, NoddiButton, NoddiLinearProgressLoader, NoddiSelectableBox } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

interface AddExtraServiceToBookingItemProps {
  bookingItem: BookingItemOnRoute;
  routeItemId: number;
  salesItemsOnBookingItem: { name: string; id: number }[];
  bookingId: number;
}

const AddExtraServiceToBookingItem = ({
  bookingItem,
  routeItemId,
  salesItemsOnBookingItem,
  bookingId
}: AddExtraServiceToBookingItemProps) => {
  const [seeAllSalesItems, setSeeAllSalesItems] = useState(false);
  const [selectedSalesItemsIds, setSelectedSalesItemsIds] = useState<number[]>([]);
  const {
    data: availableSalesItems,
    isPending: isAvailableSalesItemsPending,
    error: availableSalesItemsError
  } = noddiAsync.useGet({
    type: URLKeys.getAvailableSalesItemsForBookingItem,
    input: { bookingItemId: bookingItem.id, type: seeAllSalesItems ? null : 'extra' }
  });

  const {
    mutateAsync: addSalesItemsToBooking,
    error: addSalesItemsToBookingError,
    isPending: isAddSalesItemsToBookingPending
  } = noddiAsync.usePost({
    type: URLKeys.postAddSalesItemsToBooking,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({
          urlKey: URLKeys.getBookingItemsForRoute,
          input: { routeItemId }
        });
      }
    }
  });

  if (isAvailableSalesItemsPending) {
    return <NoddiLinearProgressLoader />;
  }

  if (availableSalesItemsError) {
    return <ApiErrorMessage error={availableSalesItemsError} />;
  }

  const numberOfSalesItemsToAdd = selectedSalesItemsIds.length;

  const ctaText =
    numberOfSalesItemsToAdd === 1
      ? `Add extra task (${numberOfSalesItemsToAdd})`
      : `Add extra tasks (${numberOfSalesItemsToAdd})`;

  const seeMoreServicesText = seeAllSalesItems ? 'Show less tasks' : 'Show more tasks';

  const filteredAvailableSalesItems = availableSalesItems.filter(
    ({ id }) => !salesItemsOnBookingItem.some((salesItem) => salesItem.id === id)
  );

  return (
    <div>
      {filteredAvailableSalesItems.map(({ id, name, price }) => {
        const isSelected = selectedSalesItemsIds.some((selectedSalesItemId) => selectedSalesItemId === id);

        return (
          <div className='mt-3' key={id}>
            <NoddiSelectableBox
              isSelected={isSelected}
              setIsSelected={() =>
                setSelectedSalesItemsIds((prevSelectedSalesItems) => {
                  if (isSelected) {
                    return prevSelectedSalesItems.filter((selectedSalesItemId) => selectedSalesItemId !== id);
                  }
                  return [...prevSelectedSalesItems, id];
                })
              }
            >
              <div className='flex items-start'>
                <p className='text-left'>
                  {name} - {formatCurrencyAmount(price, 0)}
                </p>
              </div>
            </NoddiSelectableBox>
          </div>
        );
      })}
      <NoddiButton
        endIcon={seeAllSalesItems ? 'AltArrowUp' : 'AltArrowDown'}
        variant='link'
        className='pl-0'
        onClick={() => setSeeAllSalesItems((prev) => !prev)}
      >
        {seeMoreServicesText}
      </NoddiButton>
      {addSalesItemsToBookingError && <ApiErrorMessage error={addSalesItemsToBookingError} />}
      <NoddiButton
        onClick={async () => {
          const payload = {
            bookingId,
            cars: [
              {
                licensePlateNumber: bookingItem.car.licensePlateNumber,
                countryCode: 'NO',
                salesItemIds: selectedSalesItemsIds
              }
            ]
          };
          await addSalesItemsToBooking(payload);
        }}
        className='mt-4 w-full'
        loading={isAddSalesItemsToBookingPending}
        disabled={numberOfSalesItemsToAdd === 0}
      >
        {ctaText}
      </NoddiButton>
    </div>
  );
};

export default AddExtraServiceToBookingItem;
