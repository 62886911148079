/* eslint-disable deprecation/deprecation */
import { ApiEndPoints } from '../constant/apiRoute';

export function getEndpointUrl(endpointName: string, params = {}) {
  const endpoint = ApiEndPoints.find((url) => url?.name === endpointName);
  if (!endpoint) {
    return '';
  }
  let url = endpoint.path;
  // Replace placeholders in URL with actual values
  Object.entries(params).forEach(([key, value]) => {
    // @ts-ignore TODO: fix ts error
    url = url.replace(`:${key}`, value);
  });
  return url;
}
